  
.favoriteButton
{
    border: 0 !important;
}
    
.usedButton
{
    border: 0 !important;
}
    